import { Helpers } from "../common";
import { Attachment } from "./IAttachment";

export class Complaint {
  constructor(personalInfo: ComplaintPersonalInfo, problem?: ComplaintProblem) {
    this.complaintNo = null;
    this.complaintDate = new Date();
    this.complaintTrackingNo = null;
    this.enableReply = false;
    this.handledBy = null;
    this.priorityId = null;
    this.complaintId = null;
    this.attachment = null;
    this.iAm = null;
    if (personalInfo) {
      this.complainerName = personalInfo.name;
      this.complainerEmail = personalInfo.email;
      this.complainerMobileNo = personalInfo.mobileNumber;
      this.companyId = personalInfo.company;
      this.campusId=personalInfo.campusId;
      this.cityId = personalInfo.city;
      this.iAm = personalInfo.iAm;
      this.section = personalInfo.section ? personalInfo.section : null;
      this.rollNumber = personalInfo.rollNumber
        ? personalInfo.rollNumber
        : null;
      /* this.campusId = personalInfo.campus; */
      this.campusBuilding = personalInfo.campus;
      this.officeId = personalInfo.campus
        ? personalInfo.campus
        : personalInfo.city
        ? personalInfo.city
        : personalInfo.company;
    }
    if (problem) {
      this.categoryId = problem.category;
      this.subCategoryId = problem.subCategory;
      this.complaintDescription = problem.problem;

      this.status = Helpers.newGuid();
      this.attachment = problem.attachment;
    }
  }

  complaintNo: string;
  complaintTrackingNo: string;
  complainerName: string;
  complainerMobileNo: string;
  complainerEmail: string;
  officeId: string;
  categoryId: string;
  subCategoryId: string;
  complaintDescription: string;
  priorityId: string;
  statusId: string;
  assignedTo: string;
  handledBy: string;
  enableReply: false;
  complaintId: string;
  complaintDate: Date;
  status: string;
  companyId: string;
  cityId: string;
  campusId: string;
  campusBuilding: string;
  attachment: string;
  section: string;
  rollNumber: string;
  iAm: string;
}

export class ComplaintPersonalInfo {
  constructor(personal: ComplaintPersonalInfo) {
    this.name = personal.name ? personal.name : null;
    this.mobileNumber = personal.mobileNumber ? personal.mobileNumber : null;
    this.email = personal.email ? personal.email : null;
    this.company = personal.company ? personal.company : null;
    this.city = personal.city ? personal.city : null;
    this.campus = personal.campus ? personal.campus : null;
    this.section = personal.section ? personal.section : null;
    this.rollNumber = personal.rollNumber;

    this.iAm = personal.iAm;
    this.campusId=personal.campusId;
  }
  iAm: string;
  name: string;
  company: string;
  mobileNumber: string;
  email: string;
  city: string;
  campus: string;
  campusId: string;
  section: string;
  rollNumber: string;
}

export class ComplaintProblem {
  constructor(problem: ComplaintProblem) {
    this.category = problem.category ? problem.category : null;
    this.subCategory = problem.subCategory ? problem.subCategory : null;
    this.problem = problem.problem ? problem.problem : null;
    this.attachment = problem.attachment ? problem.attachment : null;
  }
  category: string;
  subCategory: string;
  problem: string;
  attachment: string;
}

export class VerifyOtp {
  constructor(problem: VerifyOtp) {
    this.opt = problem.opt ? problem.opt : null;
  }
  opt: string;
}
