import { environment } from "../../environments/environment";

var baseUrl = environment.baseUrl + "api/";
var complaintController = baseUrl + "complaint/";
var categoryController = baseUrl + "category/";
var businessUnitController = baseUrl + "businessUnit/";

var attachmentController = baseUrl + "Attachment/";
var complaintThreadController = baseUrl + "complaintThread/";

export const APIPaths = {
  accessTokenKey: "help_auth",

  /**************************Complaint***************************/
  addNewComplaint: complaintController + "InsertUserComplaint",
  getComplaintDetails: complaintController + "GetComplaintById",
  sendOtp: complaintController + "SendOtp",
  verifyOtp: complaintController + "VerifyOtp",

  /**************************Complaint Thread***************************/
  getComplaintThread: complaintThreadController + "GetComplaintThreads",
  postComplaintThread: complaintThreadController + "InsertComplaintThreads",
  markThreadRead: complaintThreadController + "MarkThreadRead",

  /**************************Business Unit***************************/
  getAllBusinessUnits: businessUnitController + "GetAllBusinessUnits",
  getAllBUChilds: businessUnitController + "GetAllCitiesByBusinessUnit",
  getAllCampus: businessUnitController + "GetAllCampusByBusinessUnit",

  /**************************Categories***************************/
  getAllCategories: categoryController + "GetAllCategories",
  getAllSubCategories: categoryController + "GetAllSubCategories",

  /********************Attachment*********************************/
  uploadAttachemnt: attachmentController + "UploadFile",
  downloadAttachemnt: attachmentController + "DownloadFile",
  deleteAttachemnt: attachmentController + "DeleteFile",
};

export const MessageTypes = {
  error: "Error",
  info: "Info",
  failure: "Failure",
  success: "Success",
  warning: "Warning",
};
export const ResultMessages = {
  serverError: "Internal Server Error",
  requiredAllField: "Please fill all feilds",
  closeComplaintConfirmation: "Are you sure you want to resolve Complaint ",
  fileSizeLimit: "File size can not be more than 1 MB.",
  fileExtension: "File Format not supported",
  fileNotFound: "Sorry, we are unable to download this file for you.",
};

export const DropDownNames = {
  Gender: "Gender",
};
