import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TrackComplaintRestriction } from './interfaces';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'PGC Complaints';
  lang = sessionStorage.getItem('userLang');

  constructor(private router: Router) {

  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.lang = sessionStorage.getItem('userLang');
      }
    });
  }
  trackComplaint() {
    let trackComplaint = new TrackComplaintRestriction('en', false, false);
    sessionStorage.setItem('track-complaint', JSON.stringify(trackComplaint));
    this.router.navigate(['/tracking']);
  }
  goToHome(){
    this.router.navigate(['/select-language']);
  }
}
